import React from 'react';
import {Table, Button} from 'antd';
import moment from 'moment';

function ProjectTable(props) {
  const {records, goToDetail, goToHumanRule} = props;

  const columns = [
    {
      title: '編號',
      dataIndex: 'id',
      key: 'id',
      render: (_, record, index) => {
        return <div>{record.id.slice(0, 6)}</div>;
      },
      responsive: ['sm'],
    },
    {title: '專案名稱', dataIndex: 'name', key: 'name'},
    {
      title: '專案總預算',
      dataIndex: 'budget',
      key: 'budget',
      render: (_, record) => {
        return `${record.budget}`;
      },
    },
    {
      title: '建立日期',
      key: 'created',
      render: (_, record) => {
        return moment(new Date(record.created * 1000)).format('YYYY-MM-DD');
      },
    },
    {
      title: '更新日期',
      key: 'updated',
      render: (_, record) => {
        return moment(new Date(record.updated * 1000)).format('YYYY-MM-DD');
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      render: (_, record) => (
        <Button
          onClick={() => {
            if (goToDetail) {
              goToDetail(record);
            }
          }}
          type="dashed">
          專案編輯
        </Button>
      ),
    },
    {
      title: '',
      dataIndex: 'rules',
      key: 'rules',
      render: (_, record) => (
        <Button
          onClick={() => {
            if (goToHumanRule) {
              goToHumanRule(record);
            }
          }}
          type="dashed">
          人規管理
        </Button>
      ),
    },
  ];

  return <Table columns={columns} dataSource={records} />;
}

export default ProjectTable;
