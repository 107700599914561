import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {Input, InputNumber, DatePicker, Button} from 'antd';

import * as AppContext from '../../AppContext';
import TimePicker from '../../Components/TimePicker';

function ProjectDetailForm(props) {
  const {onUpdate, onGoBack} = props;
  const [values, setValues] = React.useState({
    duration: [{date: '', startTime: '', endTime: ''}],
    ...props.record,
  });

  const app = React.useContext(AppContext.Context);

  const getInputProps = (field) => {
    return {
      value: values[field],
      onChange: (e) => {
        setValues({
          ...values,
          [field]: e.target.value,
        });
      },
    };
  };

  const getInputNumberProps = (field) => {
    return {
      value: values[field],
      onChange: (value) => {
        setValues({...values, [field]: value});
      },
    };
  };

  const getDurationPickerProps = (field, index, type) => {
    if (type === 'date') {
      const currentDate = values[field][index][type];
      return {
        value: !currentDate ? currentDate : moment(currentDate),
        onChange: (value) => {
          const nextValue = values[field];
          if (value === null) {
            nextValue[index][type] = '';
          } else {
            nextValue[index][type] = moment(value).format('YYYY-MM-DD');
          }
          setValues({...values, [field]: nextValue});
        },
      };
    }

    if (type === 'startTime' || type === 'endTime') {
      return {
        value: values[field][index][type],
        onChange: (value) => {
          console.log(value);
          const nextValue = [...values[field]];
          nextValue[index][type] = value;
          setValues({...values, [field]: nextValue});
        },
      };
    }
  };

  const onAddDurationRecord = (field) => {
    const record = {date: '', time: ''};
    const nextValue = values[field];
    nextValue.push(record);
    setValues({...values, [field]: nextValue});
  };

  const onRemoveDurationRecord = (field) => {
    const nextValue = values[field];
    if (nextValue.length > 1) {
      nextValue.pop();
      setValues({...values, [field]: nextValue});
    }
  };

  async function doUpdate() {
    if (!values.name) {
      alert('Missing required field');
      return;
    }

    console.log(JSON.stringify(values, null, 2));
    alert(JSON.stringify(values, null, 2));

    app.actions.setLoading(true);
    try {
      if (!values.id) {
        setValues(await app.actions.staffCreateProject(values));
        onUpdate();
      } else {
        setValues(
          await app.actions.staffUpdateProjectDetailById(values.id, values),
        );
      }
      onGoBack();
    } catch (ex) {
      alert('API Fail:\n' + JSON.stringify(ex, null, 2));
    }
    app.actions.setLoading(false);
  }

  return (
    <Wrapper>
      <section>
        <h3>{values.name ? values.name : '新增活動'}</h3>

        <div className="field">
          <label>專案名稱</label>
          <Input {...getInputProps('name')} />
        </div>

        <div className="field">
          <label>專案總預算</label>
          <InputNumber
            {...getInputNumberProps('budget')}
            style={{marginRight: 10}}
          />{' '}
          元（新台幣）
        </div>

        <div className="field direction">
          <label>上班時段</label>
          <div className="duration-wrapper">
            {values.duration.map((record, index) => {
              return (
                <div className="duration-container" key={index}>
                  <DatePicker
                    {...getDurationPickerProps('duration', index, 'date')}
                    style={{marginRight: 10}}
                  />
                  <TimePicker
                    {...getDurationPickerProps('duration', index, 'startTime')}
                    style={{marginRight: 10}}
                  />
                  <TimePicker
                    {...getDurationPickerProps('duration', index, 'endTime')}
                    style={{marginRight: 10}}
                  />
                </div>
              );
            })}
            <div className="buttons-wrapper">
              <Button
                type="primary"
                onClick={() => onAddDurationRecord('duration')}
                style={{marginRight: 10}}>
                新增時段
              </Button>
              <Button onClick={() => onRemoveDurationRecord('duration')}>
                刪除時段
              </Button>
            </div>
          </div>
        </div>
      </section>

      <div className="line" />

      <section
        style={{maxWidth: 600, display: 'flex', justifyContent: 'flex-end'}}>
        <div className="action-bar">
          <Button onClick={onGoBack}>取消</Button>
          <Button type="primary" onClick={doUpdate}>
            {values.id ? '儲存' : '新增'}
          </Button>
        </div>
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > section {
    & > h3 {
      margin-bottom: 30px;
      font-size: 24px;
      color: #4a4a4a;
      line-height: 22px;
    }

    & > .field {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      & > label {
        width: 80px;
      }

      & > input {
        flex: 1;
        max-width: 520px;
      }

      & > textarea {
        max-width: 520px;
        resize: none;
      }

      & > .duration-wrapper {
        & > .duration-container {
          margin-bottom: 10px;
          display: flex;
          & > .buttons-wrapper {
            display: flex;
          }
        }
      }
    }

    & > .field:last-child {
      margin-bottom: 50px;
    }

    & > .action-bar {
      padding-top: 20px;
      padding-bottom: 50px;
      & > button {
        margin-right: 15px;
      }

      & button:last-child {
        margin-right: 0px;
      }
    }
  }

  & > .line {
    margin-bottom: 20px;
    max-width: 600px;
    width: 100%;
    height: 1px;
    background-color: #cccccc;
  }
`;

export default ProjectDetailForm;
