import React from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import {Table, Button, Modal, Input} from 'antd';
import * as AppContext from '../../AppContext';
import ProjectTable from './ProjectTable';
import ProjectDetailForm from './ProjectDetailForm';
import HumanRuleTable from './HumanRuleTable';

const queryString = require('query-string');

function ProjectMgrPage(props) {
  const app = React.useContext(AppContext.Context);
  const [records, setRecords] = React.useState([]);
  const [selectedRecord, setSelectedRecord] = React.useState(null);

  const params = queryString.parse(props.location.search);
  const projectId = params.id;
  const type = params.type;

  const defaultRecord = {
    name: '',
    duration: [],
  };

  const fetchRecords = React.useCallback(async () => {
    app.actions.setLoading(true);

    if (projectId) {
      try {
        setSelectedRecord(await app.actions.staffGetProjectById(projectId));
      } catch (ex) {
        console.warn('fetch project list error');
      }
    } else {
      try {
        let records = await app.actions.staffGetProjectList();
        setRecords(records);
      } catch (ex) {
        console.warn('fetch project list error');
      }
    }

    app.actions.setLoading(false);
  }, [app.actions, projectId]);

  React.useEffect(() => {
    fetchRecords();
  }, [fetchRecords]);

  function backToListView() {
    navigate(`/project`);
    setSelectedRecord(null);
  }

  return (
    <Wrapper>
      <div className="row-container">
        {!selectedRecord ? (
          <div className="header">
            <h3 className="title">專案管理</h3>
            <Button
              type="primary"
              onClick={() => setSelectedRecord(defaultRecord)}>
              新增專案
            </Button>
          </div>
        ) : (
          <div className="header">
            <Button type="primary" onClick={backToListView}>
              回專案列表
            </Button>
          </div>
        )}
      </div>

      <div className="column-container">
        {!selectedRecord ? (
          <ProjectTable
            records={records}
            goToDetail={(record) => {
              navigate(`/project?id=${record.id}`);
            }}
            goToHumanRule={(record) => {
              navigate(`/project?type=rule&id=${record.id}`);
            }}
          />
        ) : !type ? (
          <ProjectDetailForm
            record={selectedRecord}
            onGoBack={backToListView}
            onUpdate={fetchRecords}
          />
        ) : (
          // type === 'rule'
          <HumanRuleTable project={selectedRecord} />
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 60px 40px;
  background-color: white;

  & > .row-container {
    display: flex;

    & > .header {
      flex: 1;
      display: flex;
      justify-content: space-between;
      margin-right: 20px;
      margin-bottom: 20px;

      & > .title {
        font-size: 24px;
        color: #4a4a4a;
        line-height: 22px;
      }
    }

    & > .column-container {
    }
  }
`;
export default ProjectMgrPage;
