import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {navigate} from 'gatsby';
import {Table, Button, Modal, Input} from 'antd';

import * as AppContext from '../../AppContext';

function HumanRule(props) {
  const {project} = props;
  const app = React.useContext(AppContext.Context);
  const [versions, setVersions] = React.useState([]);

  const columns = [
    {title: '人規版本', dataIndex: 'version', key: 'version'},
    {
      title: '建立日期',
      key: 'created',
      render: (_, record) => {
        return moment(new Date(record.created * 1000)).format('YYYY-MM-DD');
      },
    },
    {
      title: '更新日期',
      key: 'updated',
      render: (_, record) => {
        return moment(new Date(record.updated * 1000)).format('YYYY-MM-DD');
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      render: (_, record) => (
        <Button
          onClick={() => {
            navigate(`/hr?pid=${project.id}&version=${record.version}`);
          }}
          type="dashed">
          查看
        </Button>
      ),
    },
  ];

  const fetchRecords = React.useCallback(async () => {
    app.actions.setLoading(true);

    try {
      setVersions(await app.actions.staffGetHumanRuleVersions(project.id));
    } catch (ex) {
      console.warn('fetch project list error');
    }

    app.actions.setLoading(false);
  }, [app.actions, project.id]);

  React.useEffect(() => {
    fetchRecords();
  }, [fetchRecords]);

  return (
    <Wrapper>
      <div className="main">
        <div className="header">
          <h1 className="title">人規管理: {project.name}</h1>
          <Button type="primary" onClick={() => alert('create human rule')}>
            新增人規版本
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={versions.sort((a, b) => b.created - a.created)}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: white;

  & > .main {
    & > .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      & > .title {
        font-size: 24px;
        color: #4a4a4a;
        line-height: 22px;
      }
    }
  }
`;
export default HumanRule;
